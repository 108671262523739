import { isString } from "lodash";
import {
  MetricAggregateFunction,
  MetricInterval,
  MetricType,
  MetricsDataSourceType,
  type MetricsDataSource_Db,
} from "./types";

export class MetricSourceBuilder {
  private entries: MetricSourceBuilderEntry[] = [];

  public define(
    options: MetricsDataSource_Db<string>
  ): MetricSourceBuilderEntry;
  public define(handle: string, name?: string): MetricSourceBuilderEntry;
  public define(
    handle: string | MetricsDataSource_Db<string>,
    name?: string
  ): MetricSourceBuilderEntry {
    const options = isString(handle)
      ? { name: name || handle, handle }
      : handle;
    const entry = new MetricSourceBuilderEntry(options);
    this.entries.push(entry);
    return entry;
  }

  public get(): MetricsDataSource_Db<string>[] {
    return this.entries.map((entry) => entry.get());
  }
}

export class MetricSourceBuilderEntry {
  private content: MetricsDataSource_Db<string>;

  constructor(options: Partial<MetricsDataSource_Db<string>>) {
    this.content = {
      name: "default",
      source: MetricsDataSourceType.Db,
      ...options,
    };
  }

  public type(value: MetricType) {
    this.content.type = value;
    return this;
  }

  public args(value: Record<string, any>) {
    this.content.args = value;
    return this;
  }

  public aggregate(value: MetricAggregateFunction) {
    this.content.aggregate = value;
    return this;
  }

  public precision(value: number) {
    this.content.precision = value;
    return this;
  }

  public growthRate(value: boolean) {
    this.content.growthRate = value;
    return this;
  }

  public interval(value: MetricInterval) {
    this.content.interval = value;
    return this;
  }

  public column(value: string) {
    this.content.column = value;
    return this;
  }

  public where(value: Record<string, any>) {
    this.content.where = value;
    return this;
  }

  public static fromQuery(query: string) {
    return new MetricSourceBuilderEntry({
      name: "",
    });
  }

  public toQuery() {
    return "";
  }

  public get() {
    return Object.assign({}, this.content);
  }
}
