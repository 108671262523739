import { isFunction, isString } from "lodash";
import { MetricSourceBuilder } from "../MetricSourceBuilder";
import {
  MetricsDataSourceType,
  type GroupMetricsItem,
  type MetricsDataSource,
  type MetricsDataSource_Db,
} from "../types";

type DbSource = Omit<MetricsDataSource_Db<string>, "source">;

type GenericMetricsDataSource =
  | Record<string, Omit<MetricsDataSource<string>, "handle">>
  | MetricsDataSource<string>[];

type GenericDbMetricsDataSource =
  | Record<string, Omit<DbSource, "handle"> | string>
  | DbSource[];

export const defineDbMetricsSources = (
  sources: GenericDbMetricsDataSource | { (builder: MetricSourceBuilder): void }
): GenericMetricsDataSource => {
  if (isFunction(sources)) {
    const builder = new MetricSourceBuilder();
    sources(builder);
    return builder.get();
  }

  if (Array.isArray(sources)) {
    return sources.map((s) => ({
      ...s,
      source: MetricsDataSourceType.Db,
    }));
  }

  const entries = Object.entries(sources);
  return entries.reduce((acc, [key, e]) => {
    acc[key] = {
      ...(isString(e) ? { name: e } : e),
      source: MetricsDataSourceType.Db,
    };
    return acc;
  }, {} as Record<string, Omit<MetricsDataSource<string>, "handle">>);
};

export const defineMetricsSources = (
  sources: GenericMetricsDataSource | { (builder: MetricSourceBuilder): void }
): GenericMetricsDataSource => {
  if (isFunction(sources)) {
    const builder = new MetricSourceBuilder();
    sources(builder);
    return builder.get();
  }
  return sources;
};

export const defineDbMetricsSource = (
  source: DbSource
): MetricsDataSource<string> => {
  return {
    ...source,
    source: MetricsDataSourceType.Db,
  };
};

export const defineGroupMetricsItems = <T extends GroupMetricsItem>(
  items: T[]
): T[] => items;
